import { splitProps } from "solid-js";
import { MaterialTaskDesignView } from "../../../../models";
//! lazy loading by design name causes full server reloads, reverting to basic import loading
// topics tasks
import TP01Task01 from "./tp01_task01";
import TP02Task01 from "./tp02_task01";
import TP02Task02 from "./tp02_task02";
import TP03Task01 from "./tp03_task01";
import TP04Task02 from "./tp04_task02";
import TP07Task01 from "./tp07_task01";
import TP11Task01 from "./tp11_task01";
import TP16Task01 from "./tp16_task01";
import TP17Task01 from "./tp17_task01";
import TP22Task01 from "./tp22_task01";
import DeliveryTeam from "./delivery_team";

export function Topic(props: { design_name: string } & MaterialTaskDesignView) {
  const [local, others] = splitProps(props, ["design_name"]);

  if (local.design_name === "tp01_task01") return <TP01Task01 {...others} />;
  if (local.design_name === "tp02_task01") return <TP02Task01 {...others} />;
  if (local.design_name === "tp02_task02") return <DeliveryTeam {...others} />;
  if (local.design_name === "tp03_task01") return <TP03Task01 {...others} />;
  if (local.design_name === "tp04_task01") return <DeliveryTeam {...others} />;
  if (local.design_name === "tp04_task02") return <DeliveryTeam {...others} />;
  if (local.design_name === "tp07_task01") return <TP07Task01 {...others} />;
  if (local.design_name === "tp11_task01") return <TP11Task01 {...others} />;
  if (local.design_name === "tp16_task01") return <TP16Task01 {...others} />;
  if (local.design_name === "tp17_task01") return <TP17Task01 {...others} />;
  if (local.design_name === "tp22_task01") return <TP22Task01 {...others} />;

  throw new Error(`design name not found ${local.design_name}`);
}
